import { signIn } from "next-auth/client";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { useSession } from "next-auth/client";
import Container from "components/Container";
// import { Container } from './styles';
import Button from "@material-ui/core/Button";
import { siteUrl } from "utils/urls";
import TextField from "@material-ui/core/TextField";
import CircularProgress from "@material-ui/core/CircularProgress";
function Login() {
  const [session] = useSession();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState(null);
  const router = useRouter();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Getting the error details from URL
    if (router.query.error) {
      setLoginError(router.query.error); // Shown below the input field in my example
      setEmail(router.query.email); // To prefill the email after redirect
    }
  }, [router]);

  useEffect(() => {
    if (session && session.accessToken) {
      router.push("admin");
    }
  }, [session]);

  const handleLogin = async (e) => {
    setLoading(true);
    await signIn("credentials", {
      email,
      password,
      // The page where you want to redirect to after a
      // successful login
      callbackUrl: `${siteUrl}admin`,
    });
    setLoading(false);
  };

  const handleKey = (e) => {
    if (e.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <Container outClass="cardContainer" innerClass="cardBox">
      <div className="cardBody d-flex flex-column">
        <h2>Organograma Login</h2>
        <TextField
          required
          error={loginError}
          label="E-mail"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          required
          helperText={loginError}
          error={loginError}
          label="Senha"
          value={password}
          type="password"
          onKeyDown={handleKey}
          onChange={(e) => setPassword(e.target.value)}
        />

        {loading && (
          <div className="d-flex">
            <CircularProgress />
          </div>
        )}

        <Button
          disabled={loading}
          onClick={handleLogin}
          variant="contained"
          style={{ background: "#008bd1", color: "#ffffff" }}
        >
          Entrar
        </Button>
      </div>
    </Container>
  );
}

export default Login;
